import { useEffect } from 'react';
import dayjs from 'dayjs';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import moment from 'moment';
import { isNonNullish } from 'remeda';
import apiService from 'shared/service/api.service';
import { translateAction } from 'store/translate/translate.actions';
import * as Sentry from '@sentry/react';
import { useLoadProfile } from './use-load-profile';
// @ts-ignore Escape the typing because it is an invariant; The only place it can be breached
// is in the PrivateLoader which actually enforces it by leaving the app on the loading screen as long as
// profile !== null is not satisfied
export var profileAtom = atom(null);
profileAtom.debugLabel = 'Profile';
export var useProfile = function () { return useAtomValue(profileAtom); };
export function useEnforceProfile() {
    var setProfile = useSetAtom(profileAtom);
    var _a = useLoadProfile({}), profile = _a.profile, profileError = _a.profileError;
    useEffect(function () {
        var _a, _b, _c;
        if (isNonNullish(profileError)) {
            console.error(profileError, typeof profileError);
            var redirectTenant = (_c = (_b = (_a = profileError === null || profileError === void 0 ? void 0 : profileError.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.accessible_tenants) === null || _c === void 0 ? void 0 : _c[0].subdomain;
            if (typeof redirectTenant === 'string') {
                var redirectUrl = window.location.origin.replace(apiService.tenant, redirectTenant);
                window.location.href = redirectUrl;
            }
        }
    }, [profileError]);
    useEffect(function () {
        if (profile) {
            setProfile(profile);
            translateAction.changeLang(profile.locale);
            moment.locale(profile.locale);
            dayjs.locale(profile.locale);
            Sentry.setTag('email', profile.email);
            Sentry.setUser({
                id: String(profile.id),
                email: profile.email,
                username: profile.name.full,
                roles: profile.roles,
                isAdmin: profile.isAdmin,
            });
        }
    }, [profile, setProfile]);
    return { profile: profile, profileError: profileError };
}
