import { useSelector } from 'react-redux';
import { first } from 'remeda';
import { Connector } from 'shared/components/Integrations';
// These are the magic values that qualify a third-party integration as a "connector" in the context of this feature.
// See card RCVR-152 for discussion.
var CONNECTORS = Object.values(Connector);
// Name misleading, this checks whether:
// - either a connector is active
// - OR: an integration is active, AND reconciliation is done on their end.
// We use this check to disable the manual creation of resources like invoices and payments
// which would lead to incoherent states once that same info is synced from the connector  or integrator.
export function useIsConnectorActive() {
    var company = useSelector(function (state) { return state.account.company.data; });
    // Most restrictive option if constants aren't loaded
    if (company == null)
        return true;
    var integrationsInUse = company.tp_accounting.integration_in_use;
    var maybeIntegrationInUse = first(integrationsInUse);
    if (maybeIntegrationInUse == null)
        return false;
    return CONNECTORS.includes(maybeIntegrationInUse) || company.tp_accounting.use_payment_matching;
}
