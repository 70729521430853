import { __assign } from "tslib";
import { call, crud, download, downloadBlob } from '../shared/utils/api';
export var api = {
    custom: function (options) { return call(options.url, options.method, options); },
    onBoarding: {
        stripe: function (options) { return call('/private_api/onboarding/stripe', 'POST', options); },
        noPayment: function (options) { return call('/private_api/onboarding/no_payment', 'POST', options); },
        codaboxToken: function (options) { return call('/private_api/onboarding/codabox', 'POST', options); },
        codaboxRequest: function (options) {
            return call('/private_api/onboarding/codabox/request_token', 'POST', options);
        },
        ponto: function (options) { return call('/private_api/onboarding/ponto', 'POST', options); },
        bankAccount: function (options) { return call('/private_api/onboarding/company_bank_account', 'POST', options); },
        debtCollectionSettings: function (options) {
            return call('/private_api/onboarding/company_settings', 'POST', options);
        },
        onBoardingFiles: function (options) {
            return call('/private_api/onboarding/company_files', 'POST', __assign(__assign({}, options), { fileUpload: true }));
        },
        invoicing_settings: function (options) {
            return call('/private_api/onboarding/invoicing_settings', 'POST', options);
        },
        horus: function (options) { return call('/private_api/onboarding/horus', 'POST', options); },
        horusFetchCompanies: function (options) {
            return call('/private_api/onboarding/horus/fetch_companies', 'GET', options);
        },
        teamleader: function (options) { return call('/private_api/onboarding/teamleader', 'POST', options); },
        exact: function (options) { return call('/private_api/onboarding/exact', 'POST', options); },
        exactFetchCompanies: function (options) {
            return call('/private_api/onboarding/exact/fetch_companies', 'GET', options);
        },
        yuki: function (options) { return call('/private_api/onboarding/yuki', 'POST', options); },
        twikey: function (options) { return call('/private_api/onboarding/twikey', 'POST', options); },
        billit: function (options) { return call('/private_api/onboarding/billit', 'POST', options); },
        uploadTerms: function (options) {
            return call('/private_api/onboarding/terms_and_conditions_files', 'POST', options);
        },
    },
    settings: {
        constants: function (options) { return call('/private_api/constants', 'GET', options); },
        fetchAddress: function (options) { return call('/private_api/fetch_address', 'POST', options); },
        updateBillitSettings: function (options) { return call('/private_api/billit_configurations', 'PUT', options); },
        updateYukiSettings: function (options) { return call('/private_api/yuki_configurations', 'PUT', options); },
        updateHorusSettings: function (options) { return call('/private_api/horus_configurations', 'PUT', options); },
        updateDbasicsSettings: function (options) { return call('/private_api/dbasics_configurations', 'PUT', options); },
        addTwikey: function (options) { return call('/private_api/billing/twikey', 'POST', options); },
        updateCompany: function (options) {
            return call('/private_api/company_configurations/company_profile', 'PUT', options);
        },
        updateTeamleaderSettings: function (options) {
            return call('/private_api/teamleader_configurations', 'PUT', options);
        },
        updateInvoiceConfigurations: function (options) {
            return call('/private_api/company_configurations/invoice_configuration', 'PUT', options);
        },
        editDebtCollection: function (options) {
            return call('/private_api/company_configurations/company_debt_collection', 'PUT', options);
        },
        updateExactSettings: function (options) { return call('/private_api/exact_configurations', 'PUT', options); },
        exactFetchCompanies: function (options) {
            return call('/private_api/exact_configurations/fetch_companies', 'GET', options);
        },
        horusFetchCompanies: function (options) {
            return call('/private_api/horus_configurations/fetch_companies', 'GET', options);
        },
        codaboxToken: function (options) { return call('/private_api/codabox', 'POST', options); },
        codaboxRequest: function (options) { return call('/private_api/codabox/request_token', 'POST', options); },
        ponto: function (options) { return call('/private_api/ponto', 'POST', options); },
        editBankingInfo: function (options) { return call('/private_api/billing/stripe', 'PUT', options); },
        setDigitealConfiguration: function (options) {
            return call('/private_api/digiteal_configurations', 'PUT', options);
        },
        resetSecretId: function (options) { return call('/private_api/api_secrets', 'POST', options); },
        askUpgradePackage: function (options) { return call('/private_api/api_subscriptions', 'POST', options); },
        getDigitealCGV: function (options) { return call('/private_api/digiteal_tos', 'GET', options); },
        syncIntegration: function (options) { return call('/private_api/accounting_synchronization', 'POST', options); },
    },
    session: {
        login: function (options) { return call('/auth/sign_in', 'POST', options); },
        logout: function (options) { return call('/auth/sign_out', 'DELETE', options); },
        resetPassword: function (options) { return call('/auth/password', 'POST', options); },
        getTokenToResetPassword: function (options) {
            return call('/auth/password/edit?config=default', 'GET', options);
        },
        editPassword: function (options) { return call('/auth/password', 'PUT', options); },
    },
    company: {
        detail: function (options) { return call('/private_api/company_configurations', 'GET', options); },
        update: function (options) { return call('/private_api/company_configurations', 'PUT', options); },
        updateCompanyFiles: function (options) {
            return call('/private_api/company_configurations/company_files_upload', 'PUT', options);
        },
        getTerms: function (options) {
            return downloadBlob("/private_api/company_configurations/terms_and_conditions_files?=".concat(options.id), 'POST', options);
        },
        uploadTerms: function (options) {
            return call("/private_api/company_configurations/terms_and_conditions_files/".concat(options.id), 'PUT', options);
        },
    },
    colleague: __assign(__assign({}, crud('/private_api/colleagues')), { add: function (options) { return call('/private_api/users/invite_colleague', 'POST', options); } }),
    signup: {
        info: function (options) { return call('/auth/invitation', 'PUT', options); },
    },
    activity: {
        list: function (options) { return call('/private_api/activities', 'GET', options); },
        detail: function (options) { return call("/private_api/activities/".concat(options.id), 'GET', options); },
        markAsRead: function (options) {
            return call("/private_api/activities/".concat(options.id, "/mark_as_read"), 'POST', options);
        },
        markAllAsRead: function (options) { return call('/private_api/activities/mark_as_read', 'POST', options); },
    },
    account: {
        dashboard: function (options) { return call('/private_api/dashboard', 'GET', options); },
        dashboardBalance: function (options) { return call('/private_api/debtors_detailed_balance', 'GET', options); },
        kanban: function (options) { return call('/private_api/kanban', 'GET', options); },
        kanbanColumn: function (options) { return call("/private_api/kanban/".concat(options.id), 'GET', options); },
    },
    product: __assign({}, crud('/private_api/products')),
    client: __assign(__assign({}, crud('/private_api/debtors')), { addComment: function (options) { return call("/private_api/debtors/".concat(options.id, "/comments"), 'POST', options); }, assignToAccManager: function (options) {
            return call("/private_api/debtors/".concat(options.id, "/assign_debtor"), 'POST', options);
        }, assignMultipleToUser: function (options) { return call('/private_api/debtors/assign_debtors', 'POST', options); }, getFinancialData: function (options) {
            return call("/private_api/debtors/".concat(options.id, "/balance_detail"), 'GET', options);
        }, pauseDebtor: function (options) {
            return call("/private_api/debtors/".concat(options.id, "/pause_debtor"), 'POST', options);
        }, deleteMultiple: function (options) { return call('/private_api/debtors/destroy_multiple', 'POST', options); }, pauseMultiple: function (options) { return call('/private_api/debtors/pause_debtors', 'POST', options); }, batchAction: function (options) { return call('/private_api/debtors/batch', 'POST', options); }, import: {
            getAllToConfirm: function (options) { return call('/private_api/import_debtors', 'GET', options); },
            getToConfirm: function (options) { return call("/private_api/import_debtors/".concat(options.id), 'GET', options); },
            validateAll: function (options) { return call('/private_api/import_debtors', 'PUT', options); },
            deleteToConfirm: function (options) {
                return call("/private_api/import_debtors/".concat(options.id), 'DELETE', options);
            },
        } }),
    transactions: {
        getReconciliation: function (options) { return call('/private_api/manual_reconciliation', 'GET', options); },
        manualReconciliation: function (options) { return call('/private_api/manual_reconciliation', 'POST', options); },
        payment: function (options) { return call('/private_api/payments', 'GET', options); },
        ignorePayments: function (options) { return call('/private_api/payments/ignore', 'POST', options); },
        unignorePayments: function (options) { return call('/private_api/payments/unignore', 'POST', options); },
        unmatchManualPayment: function (options) {
            return call("/private_api/unmatch_manual_payments/".concat(options.id), 'PUT', options);
        },
        unmatchExternalPayment: function (options) {
            return call("/private_api/unmatch_external_payments/".concat(options.id), 'PUT', options);
        },
    },
    creditNote: __assign(__assign({}, crud('/private_api/credit_notes')), { send: function (options) {
            return call("/private_api/credit_notes/".concat(options.id, "/send_credit_note_to_debtor"), 'POST', options);
        }, activity: function (options) { return call("/private_api/invoices/".concat(options.id, "/activities"), 'GET', options); }, settle: function (options) {
            return call("/private_api/credit_notes/".concat(options.id, "/credit_note_settlement"), 'POST', options);
        }, batchAction: function (options) { return call('/private_api/credit_notes/batch', 'POST', options); }, invoiceSearch: function (options) { return call('/private_api/credit_note/invoices', 'GET', options); }, updateOriginalFile: function (options) {
            return call("/private_api/credit_notes/".concat(options.id, "/update_pdf"), 'PUT', options);
        } }),
    invoice: __assign(__assign({}, crud('/private_api/invoices')), { deleteMultiple: function (options) { return call('/private_api/invoices/destroy_multiple', 'POST', options); }, import: {
            ubl: function (options) { return call('/private_api/ubl', 'POST', options); },
            pdf: function (options) { return call('/private_api/invoice_with_pdf', 'POST', options); },
            csv: function (options) { return call('/private_api/import_invoices', 'POST', options); },
            getAllToConfirm: function (options) { return call('/private_api/import_invoices', 'GET', options); },
            getToConfirm: function (options) { return call("/private_api/import_invoices/".concat(options.id), 'GET', options); },
            validateAll: function (options) { return call('/private_api/import_invoices', 'PUT', options); },
            deleteToConfirm: function (options) {
                return call("/private_api/import_invoices/".concat(options.id), 'DELETE', options);
            },
        }, additionalFile: {
            detail: function (options) {
                return call("/private_api/invoices/".concat(options.id, "/additional_files"), 'GET', options);
            },
            add: function (options) { return call('/private_api/additional_files', 'POST', options); },
            delete: function (options) { return call("/private_api/additional_files/".concat(options.id), 'DELETE', options); },
        }, debtorReaction: {
            qrCode: function (options) { return call("/private_api/invoices/".concat(options.id, "/qr_code"), 'GET', options); },
            getUnpaid: function (options) {
                return call("/private_api/invoices/".concat(options.id, "/debtor_reaction_balance"), 'GET', options);
            },
            comment: function (options) {
                return call("/private_api/invoices/".concat(options.id, "/debtor_reaction"), 'POST', options);
            },
            getInvoices: function (options) {
                return call("/private_api/invoices/".concat(options.id, "/debtor_invoices"), 'GET', options);
            },
            getCreditNotes: function (options) {
                return call("/private_api/invoices/".concat(options.id, "/debtor_credit_notes"), 'GET', options);
            },
            getGroupedInvoicePaymentInfo: function (options) {
                return call("/private_api/invoices/".concat(options.id, "/debtor_digiteal_grouped_payment"), 'POST', options);
            },
        }, updateOriginalFile: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/update_pdf"), 'PUT', options);
        }, toHandle: function (options) { return call('/private_api/actionable_invoices', 'GET', options); }, document: function (options) {
            return downloadBlob("/private_api/invoices/".concat(options.id, "/invoice_document"), 'POST', options);
        }, previewTemplate: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/preview_template"), 'GET', options);
        }, setSendingOptions: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/sending_options"), 'PUT', options);
        }, publicDocument: function (options) {
            return download("/private_api/invoices/".concat(options.id, "/document?additional_file_id=").concat(options.data.additional_file_id, "&token=").concat(options.data.token, "&document_type=").concat(options.data.document_type), 'GET', options);
        }, copy: function (options) { return call("/private_api/invoices/".concat(options.id, "/invoice_copy"), 'POST', options); }, assignToAccManager: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/assign_invoice"), 'POST', options);
        }, getRecoveryPlans: function (options) {
            return call('/private_api/invoices/invoices_recovery_plan', 'GET', options);
        }, setRecoveryPlan: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/invoices_recovery_plan"), 'POST', options);
        }, postponable: function (options) { return call('/private_api/postponable_invoices', 'GET', options); }, postpone: function (options) { return call("/private_api/invoices/".concat(options.id, "/postpone"), 'POST', options); }, forceReminder: function (options) { return call('/private_api/force_reminders', 'POST', options); }, postponeMultiple: function (options) { return call('/private_api/postponable_invoices', 'POST', options); }, activity: function (options) { return call("/private_api/invoices/".concat(options.id, "/activities"), 'GET', options); }, send: function (options) { return call("/private_api/invoices/".concat(options.id, "/send_to_debtor"), 'POST', options); }, getGroupedInvoices: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/grouped_invoices_data"), 'GET', options);
        }, comment: function (options) { return call("/private_api/invoices/".concat(options.id, "/comments"), 'POST', options); }, conversationMessage: function (options) {
            return call("/private_api/invoice_feedback/".concat(options.id, "/conversation_message"), 'POST', options);
        }, addPayment: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/manual_payments"), 'POST', options);
        }, 
        // mark as
        paid: function (options) { return call("/private_api/invoices/".concat(options.id, "/mark_as_paid"), 'POST', options); }, lost: function (options) { return call("/private_api/invoices/".concat(options.id, "/lose_invoice"), 'POST', options); }, disputed: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/mark_as_disputed"), 'POST', options);
        }, pause: function (options) { return call("/private_api/invoices/".concat(options.id, "/update_pause"), 'POST', options); }, batchAction: function (options) { return call('/private_api/invoices/batch', 'POST', options); }, 
        // Actionable apis
        ignoreLateFees: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/ignore_late_fees"), 'POST', options);
        }, additionalReminder: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/additional_reminder"), 'POST', options);
        }, callcenter: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/callcenter"), 'POST', options);
        }, lawyer: function (options) { return call("/private_api/invoices/".concat(options.id, "/lawyer"), 'POST', options); }, closeLawyer: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/lawyer/close"), 'POST', options);
        }, createFormalNotice: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/formal_notice"), 'POST', options);
        }, bailiff: function (options) { return call("/private_api/invoices/".concat(options.id, "/bailiff"), 'POST', options); }, closeBailiff: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/bailiff/close"), 'POST', options);
        }, markAsToBeProccesed: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/mark_as_to_be_processed"), 'POST', options);
        }, sendToThirdParty: function (options) {
            return call("/private_api/invoices/".concat(options.id, "/send_to_third_party"), 'POST', options);
        } }),
    template: __assign(__assign({}, crud('/private_api/templates')), { duplicate: function (options) {
            return call("/private_api/templates/".concat(options.id, "/template_copy"), 'POST', options);
        }, preview: function (options) {
            return (options.download ? downloadBlob : call)("/private_api/templates/".concat(options.id, "/template_preview"), 'POST', options);
        } }),
    recoveryPlan: __assign(__assign({}, crud('/private_api/recovery_plans')), { duplicate: function (options) {
            return call("/private_api/recovery_plans/".concat(options.id, "/recovery_plan_copy"), 'POST', options);
        }, switchPlansInvoices: function (options) { return call('/private_api/recovery_plan_switch', 'POST', options); }, getPlansInvoices: function (options) { return call('/private_api/recovery_plan_switch', 'GET', options); } }),
    customVariable: __assign({}, crud('/private_api/custom_variables')),
    thirdpartyCase: {
        list: function (options) { return call('/private_api/thirdparty_cases', 'GET', options); },
        detail: function (options) { return call("/private_api/thirdparty_cases/".concat(options.id), 'GET', options); },
    },
    twikey: {
        login: function (options) {
            return call('https://api.twikey.com/creditor', 'POST', __assign(__assign({}, options), { raw: true }));
        },
        invite: function (options) {
            return call('https://api.twikey.com/creditor/invite', 'POST', __assign(__assign({}, options), { raw: true }));
        },
    },
    tables: {
        getView: function (options) { return call("/private_api/views/".concat(options.id), 'GET', options); },
        getViews: function (options) { return call('/private_api/views', 'GET', options); },
        updateViews: function (options) { return call('/private_api/views', 'POST', options); },
        getColumnsAvailable: function (options) { return call('/private_api/custom_columns', 'GET', options); },
        getFiltersAvailable: function (options) { return call('/private_api/custom_filters', 'GET', options); },
        getFiltersName: function (options) { return call('/private_api/filter_names', 'GET', options); },
        export: function (options) { return call('/private_api/download_resources', 'POST', options); },
    },
};
export default api;
