import { __assign, __awaiter, __generator, __read } from "tslib";
import { useEffect, useState } from 'react';
import { ORVAL_AXIOS_INSTANCE } from 'api/mutator/custom-instance';
import { isNullish } from 'remeda';
import { REDUX_AXIOS_INSTANCE } from 'shared/utils/api';
import { config } from 'store/constants';
import { useAuth0 } from '@auth0/auth0-react';
export var AUTH0_SCOPE = 'openid profile email offline_access';
export var useAuthenticate = function () {
    var _a = useAuth0(), isAuthenticated = _a.isAuthenticated, getAccessTokenSilently = _a.getAccessTokenSilently, auth0Error = _a.error, isAuth0Loading = _a.isLoading;
    var _b = __read(useState(null), 2), auth0AccessToken = _b[0], setAuth0AccessToken = _b[1];
    var _c = __read(useState(false), 2), haveInterceptorsBeenSet = _c[0], setHaveInterceptorsBeenSet = _c[1];
    useEffect(function () {
        var getToken = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: config.auth0.audience,
                                scope: AUTH0_SCOPE,
                            },
                        })];
                    case 1:
                        token = _a.sent();
                        setAuth0AccessToken(token);
                        return [2 /*return*/];
                }
            });
        }); };
        if (isAuthenticated && isNullish(auth0AccessToken))
            getToken();
    });
    useEffect(function () {
        if (isNullish(auth0AccessToken))
            return function () { };
        var orvalInterceptor = ORVAL_AXIOS_INSTANCE.interceptors.request.use(function (config) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                config.headers = __assign(__assign({}, config.headers), { Authorization: "Bearer ".concat(auth0AccessToken) });
                return [2 /*return*/, config];
            });
        }); });
        var reduxInterceptor = REDUX_AXIOS_INSTANCE.interceptors.request.use(function (config) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                config.headers = __assign(__assign({}, config.headers), { Authorization: "Bearer ".concat(auth0AccessToken) });
                return [2 /*return*/, config];
            });
        }); });
        setHaveInterceptorsBeenSet(true);
        return function () {
            ORVAL_AXIOS_INSTANCE.interceptors.request.eject(orvalInterceptor);
            REDUX_AXIOS_INSTANCE.interceptors.request.eject(reduxInterceptor);
        };
    }, [auth0AccessToken]);
    return {
        isAuth0Loading: isAuth0Loading,
        isAuthenticated: isAuthenticated,
        canProceedIntoAuthenticatedDomain: isAuthenticated && !isNullish(auth0AccessToken) && haveInterceptorsBeenSet,
        auth0AccessToken: auth0AccessToken,
        auth0Error: auth0Error,
    };
};
