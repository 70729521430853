import { __read, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import moment from 'moment';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import DateItem from 'shared/components/DateItem';
import HTML from 'shared/components/HTML/HTML';
import FormSection from 'shared/forms/FormSection';
import { dateFromNow, formattedDate } from 'shared/utils/view';
import { accountActions } from 'store/account/account.actions';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { styled } from '@stitches/react';
import { useQueryClient } from '@tanstack/react-query';
import { Icon, IconName } from '../Icon';
import Conversation from './Conversation';
import styleIdentifiers from './ActivityDetail.scss';
var styles = classNames.bind(styleIdentifiers);
var EmailStatusHistory = styled('div', {});
var EmailStatusHistoryItem = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    padding: '10px 0px 0px 5px',
});
export default function ActivityDetail(_a) {
    var _b, _c;
    var item = _a.item;
    var activity = useSelector(function (state) { return state.account.activity; });
    var invoice = useSelector(function (state) { var _a; return (_a = state.invoice.current) === null || _a === void 0 ? void 0 : _a.data; });
    var creditNote = useSelector(function (state) { var _a; return (_a = state.creditNote.current) === null || _a === void 0 ? void 0 : _a.data; });
    var canUseEmailStatus = useSelector(function (state) { return state.account.company.data.package; }).can_use_emails_activity;
    var _d = useTranslation(), t = _d.t, currentLang = _d.currentLang;
    var queryClient = useQueryClient();
    var location = useLocation();
    var history = useHistory();
    var flattenedStatuses = Object.keys((_b = item.email_statuses) !== null && _b !== void 0 ? _b : {}).reduce(function (memo, currentKey) { return __spreadArray(__spreadArray([], __read(memo), false), __read(item.email_statuses[currentKey].map(function (date) { return ({
        status: currentKey,
        timestamp: moment(date),
    }); })), false); }, []);
    var sortedStatuses = flattenedStatuses.sort(function (a, b) { return b.timestamp.diff(a.timestamp); });
    var reloadInvoice = function () {
        invoiceActions.activity({
            id: invoice.id,
            noLoading: true,
            noReset: true,
        });
    };
    var reloadCreditNote = function () {
        creditNoteActions.activity({
            id: creditNote.id,
            noLoading: true,
            noReset: true,
        });
    };
    var reloadActivities = function () {
        accountActions.dashboard({
            noLoading: true,
        });
        accountActions.activityPage(qs.parse(location.search));
    };
    // Possible (load both creditNote and invoice (to check))
    var reload = function () {
        // Detail invoice
        if (invoice)
            reloadInvoice();
        // Detail credit note
        if (creditNote)
            reloadCreditNote();
        // Detail activity
        if (activity && activity.loaded)
            reloadActivities();
    };
    useEffect(function () {
        if (item.new) {
            accountActions.activitySeen({
                id: item.id,
                noLoading: true,
                noFeedback: true,
                callback: function () {
                    queryClient.invalidateQueries(['notifications', 'count']);
                },
            });
        }
    }, [item]);
    var detailInvoice = function () {
        dialogHide(DialogShowId.CUSTOM);
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            onClose: reload,
            children: (React.createElement(ToHandleDetail, { isCreditNote: item.tracked_type === 'CreditNote', id: item.tracked_id, modal: true })),
        });
    };
    var detailDebtor = function () {
        dialogHide(DialogShowId.CUSTOM);
        history.push("/clients/".concat(item.tracked_id));
    };
    var detailTask = function () {
        dialogHide(DialogShowId.CUSTOM);
        history.push("/tasks/".concat(item.tracked_id));
    };
    var detailPaymentPlan = function () {
        dialogHide(DialogShowId.CUSTOM);
        history.push("/payment-plan/".concat(item.payment_plan_id));
    };
    var getIcon = function () {
        switch (item.tracked_type) {
            case 'CreditNote':
                return IconName.CREDIT_NOTE;
            case 'Invoice':
                return IconName.INVOICE;
            case 'Debtor':
                return IconName.CLIENT;
            case 'Question':
                return IconName.ALERT_CIRCLE;
            case 'CompanyDetail':
                return IconName.SETTINGS_GEAR;
            default:
                return IconName.ALERT_CIRCLE;
        }
    };
    var onSubmitMessage = function () {
        reload();
    };
    var downloadDocument = function () {
        invoiceActions.document({
            id: item.tracked_id,
            type: item.document_type,
        });
    };
    var date = dateFromNow(item.date, currentLang);
    var titleKey = function () {
        if (item.activity_type === 'lawyer') {
            return i18nKeys.INVOICE.LAWYER_CASE;
        }
        if (item.activity_type === 'callcenter') {
            return i18nKeys.INVOICE.CALLCENTER_CASE;
        }
        if (item.activity_type === 'bailiff')
            return i18nKeys.INVOICE.BAILIFF_CASE;
        if (item.payment_plan_id)
            return i18nKeys.PAYMENT_PLAN.TITLE.ONE;
        if (item.tracked_type === 'CreditNote')
            return i18nKeys.ACTIVITIES.UPDATE_STATUS_CREDIT_NOTE;
        if (item.tracked_type === 'Invoice') {
            return i18nKeys.ACTIVITIES.UPDATE_STATUS_INVOICE;
        }
        if (item.tracked_type === 'Debtor') {
            return i18nKeys.ACTIVITIES.UPDATE_STATUS_DEBTOR;
        }
        if (item.tracked_type === 'CompanyDetail') {
            return i18nKeys.ACTIVITIES.EDIT_COMPANY;
        }
        if (item.tracked_type === 'Task') {
            return i18nKeys.ACTIVITIES.UPDATE_STATUS_TASK;
        }
        return i18nKeys.DETAILS;
    };
    var title = t(titleKey());
    var showPaymentPlan = item.payment_plan_id;
    var showClient = item.tracked_type === 'Debtor' && !showPaymentPlan;
    return (React.createElement("div", { className: styles('ActivityDetail') },
        React.createElement(Card, { title: title, classContent: styles('modal-body') },
            React.createElement("div", null,
                React.createElement("div", { className: styles('content') },
                    React.createElement("div", { className: styles('left') },
                        React.createElement(Icon, { name: getIcon(), "font-size": "24px", className: styles('icon') }),
                        React.createElement("div", { className: styles('text') },
                            React.createElement(HTML, { html: item.description }),
                            item.payment_information && (React.createElement(React.Fragment, null,
                                React.createElement("div", { style: { display: 'flex' } },
                                    React.createElement("b", { style: { marginRight: '5px' } },
                                        t(i18nKeys.AMOUNT),
                                        " : "),
                                    React.createElement(Amount, { value: item.payment_information.localized_money_object.amount, localizedValue: item.payment_information.amount, suffix: item.payment_information.currency })),
                                React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.DATE),
                                        " : "),
                                    React.createElement(DateItem, { lg: currentLang, date: item.payment_information.date })),
                                React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.MODALITY),
                                        " : "),
                                    React.createElement("span", null, item.payment_information.modality)),
                                item.payment_information.notes && (React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.NOTES),
                                        " : "),
                                    React.createElement("span", null, item.payment_information.notes))),
                                item.payment_information.counterparty_name && (React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.ACTIVITIES.COUNTERPART),
                                        " : "),
                                    React.createElement("span", null, item.payment_information.counterparty_name))),
                                item.payment_information.external_id && (React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.ACTIVITIES.EXTERNAL_ID),
                                        " : "),
                                    React.createElement("span", null, item.payment_information.external_id))))),
                            item.comment && (React.createElement("div", null,
                                React.createElement("b", null,
                                    t(i18nKeys.COMMENT),
                                    " : "),
                                React.createElement("span", null, item.comment))),
                            item.reason && (React.createElement("div", null,
                                React.createElement("b", null,
                                    t(i18nKeys.REASON),
                                    " : "),
                                React.createElement("span", null, item.reason))),
                            item.end_pause_at && (React.createElement("div", null,
                                React.createElement("b", null,
                                    t(i18nKeys.END_OF_PAUSE),
                                    " : "),
                                React.createElement(DateItem, { lg: currentLang, date: item.end_pause_at }))))),
                    React.createElement("div", { className: styles('right') },
                        item.attachment_url && (React.createElement("div", { className: styles('img-wrapper') },
                            React.createElement("img", { src: item.attachment_url, alt: "avatar" }))),
                        React.createElement("span", { className: styles('text') },
                            date,
                            " ",
                            t(i18nKeys.FROM),
                            " ",
                            React.createElement("span", null, item.author)))),
                sortedStatuses.length !== 0 && canUseEmailStatus && (React.createElement(EmailStatusHistory, null,
                    React.createElement(FormSection, { title: t(i18nKeys.MAILS.HISTORY) }, sortedStatuses.map(function (_item, index) { return (React.createElement(EmailStatusHistoryItem, { key: index },
                        React.createElement("span", null, t(i18nKeys.MAILS.STATUS[_item.status.toUpperCase()])),
                        React.createElement("span", { style: { fontStyle: 'italic', color: '#bbb' } }, formattedDate(_item.timestamp, currentLang, 'DD/MM/YYYY, LT')))); }))))),
            ((_c = item.invoice_feedback) === null || _c === void 0 ? void 0 : _c.conversation) && (React.createElement(Conversation, { className: styles('conversation'), invoiceId: item.tracked_id, conversation: item.invoice_feedback, onSubmit: onSubmitMessage, canNotComment: item.invoice_feedback.closed })),
            React.createElement("div", { className: styles('bottom') },
                item.tracked_type === 'Invoice' && (React.createElement("div", { className: styles('see-detail'), onClick: detailInvoice },
                    t(i18nKeys.ACTIVITIES.SEE_INVOICE),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                item.tracked_type === 'CreditNote' && (React.createElement("div", { className: styles('see-detail'), onClick: detailInvoice },
                    t(i18nKeys.ACTIVITIES.SEE_CREDIT_NOTE),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                item.tracked_type === 'Task' && (React.createElement("div", { className: styles('see-detail'), onClick: detailTask },
                    t(i18nKeys.ACTIVITIES.SEE_TASK),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                showPaymentPlan && (React.createElement("div", { className: styles('see-detail'), onClick: detailPaymentPlan },
                    t(i18nKeys.ACTIVITIES.SEE_PAYMENT_PLAN),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                showClient && (React.createElement("div", { className: styles('see-detail'), onClick: detailDebtor },
                    t(i18nKeys.ACTIVITIES.SEE_CLIENT),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                item.has_document && (React.createElement("div", { className: styles('document'), onClick: downloadDocument },
                    React.createElement(Icon, { name: IconName.PDF, className: styles('icon') }),
                    React.createElement("span", { className: styles('icon-txt') },
                        ' ',
                        t(i18nKeys.ACTIVITIES.DOWNLOAD_DOCUMENT))))))));
}
