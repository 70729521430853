import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect, Input, SwitchInput } from 'shared/io';
import { CGV, InterestCalculationType, SalePreferencesCGVFieldsName, } from '../PreferencesForm.constants';
import styleIdentifiers from './SalePreferencesPartialForm.scss';
import { getCalculationTypeOptions, getDefaultInterest, getFieldErrors, getInterestCalculationTypeOptions, } from './SalePreferencesPartialForm.utils';
var styles = classNames.bind(styleIdentifiers);
export var SalePreferencesPartialFormItems = function (_a) {
    var _b, _c, _d, _e;
    var parent = _a.parent;
    var t = useTranslation().t;
    var _f = useFormContext(), watch = _f.watch, setValue = _f.setValue, register = _f.register, errors = _f.formState.errors;
    var values = watch("".concat(CGV, ".").concat(parent));
    var fix_fee = values.fix_fee, interest = values.interest, penalty_clause = values.penalty_clause;
    var deleteProp = function (el) {
        setValue("".concat(CGV, ".").concat(parent), omit(values, el));
    };
    var fieldErrors = getFieldErrors(errors, parent);
    var onSwitchChange = function (target, value) {
        if (value) {
            setValue("".concat(CGV, ".").concat(parent, ".").concat(target), getDefaultInterest(), {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
        }
        else {
            deleteProp(target);
        }
    };
    var isPenaltyClausePercentage = (penalty_clause === null || penalty_clause === void 0 ? void 0 : penalty_clause.type) === InterestCalculationType.PERCENTAGE;
    return (React.createElement("div", { className: styles('sale-conditions-partial-form-fee') },
        React.createElement("h4", null, t(i18nKeys.LATE_FEES)),
        React.createElement("div", { className: styles('grid-row', 'space') },
            React.createElement("div", { className: styles('col-3') },
                React.createElement(SwitchInput, { className: styles('switch'), label: t(i18nKeys.FORM.PREFERENCES.INTEREST), inversed: true, value: !!interest, onChange: function (newValue) {
                        onSwitchChange(SalePreferencesCGVFieldsName.INTEREST, newValue);
                    } })),
            interest && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('col-3') },
                    React.createElement(Controller, { name: "".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesCGVFieldsName.INTEREST_PERIOD), rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesCGVFieldsName.INTEREST_PERIOD), label: t(i18nKeys.FORM.PREFERENCES.PERIODICITY), items: getCalculationTypeOptions(t), keyText: "description", keyValue: "value", canUnselect: false, noMargin: true })); } })),
                React.createElement("div", { className: styles('col-3') },
                    React.createElement(Input, { type: "number", min: "0", max: "100", label: t(i18nKeys.FORM.PAYMENT_DELAY.INTEREST_RATE), errorMessage: (_b = fieldErrors === null || fieldErrors === void 0 ? void 0 : fieldErrors.interest) === null || _b === void 0 ? void 0 : _b.value, noMargin: true, register: register("".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesCGVFieldsName.INTEREST_VALUE), {
                            required: true,
                            min: 0,
                            max: 100,
                        }) }))))),
        React.createElement("div", { className: styles('grid-row', 'space') },
            React.createElement("div", { className: styles('col-3') },
                React.createElement(SwitchInput, { label: t(i18nKeys.FORM.PREFERENCES.FIX_FEE), inversed: true, value: !!fix_fee, onChange: function (value) {
                        return onSwitchChange(SalePreferencesCGVFieldsName.FIX_FEE, value);
                    } })),
            fix_fee && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('col-3') },
                    React.createElement(Input, { type: "number", min: "0", label: t(i18nKeys.FORM.PREFERENCES.AMOUNT_EURO), errorMessage: (_c = fieldErrors === null || fieldErrors === void 0 ? void 0 : fieldErrors.fix_fee) === null || _c === void 0 ? void 0 : _c.value, noMargin: true, register: register("".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesCGVFieldsName.FIX_FEE_VALUE), {
                            required: true,
                            min: 0,
                        }) }))))),
        React.createElement("div", { className: styles('grid-row') },
            React.createElement("div", { className: styles('col-3') },
                React.createElement(SwitchInput, { inversed: true, value: !!penalty_clause, label: t(i18nKeys.PENALTY_CLAUSE), onChange: function (newValue) {
                        onSwitchChange(SalePreferencesCGVFieldsName.PENALTY_CLAUSE, newValue);
                    } })),
            penalty_clause && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('col-3') },
                    React.createElement(Controller, { name: "".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesCGVFieldsName.PENALTY_CLAUSE_TYPE), rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesCGVFieldsName.PENALTY_CLAUSE_TYPE), label: t(i18nKeys.FORM.PREFERENCES.CLAUSE_TYPE), items: getInterestCalculationTypeOptions(t), keyText: "description", keyValue: "value", canUnselect: false, noMargin: true })); } })),
                isPenaltyClausePercentage && (React.createElement("div", { className: styles('col-3') },
                    React.createElement(Input, { type: "number", min: "0", step: "0.1", label: t(i18nKeys.FORM.PREFERENCES.PENALTY_CLAUSE_MIN, {
                            text: '€',
                        }), errorMessage: (_d = fieldErrors === null || fieldErrors === void 0 ? void 0 : fieldErrors.penalty_clause) === null || _d === void 0 ? void 0 : _d.minimum, noMargin: true, register: register("".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesCGVFieldsName.PENALTY_CLAUSE_MINIMUM), {
                            required: true,
                            min: 0,
                        }) }))),
                React.createElement("div", { className: styles('col-3') },
                    React.createElement(Input, { type: "number", min: "0", step: "0.1", max: isPenaltyClausePercentage ? '100' : undefined, label: t(i18nKeys.FORM.PREFERENCES.PENALTY_CLAUSE_VALUE, {
                            text: isPenaltyClausePercentage ? '%' : '€',
                        }), errorMessage: (_e = fieldErrors === null || fieldErrors === void 0 ? void 0 : fieldErrors.penalty_clause) === null || _e === void 0 ? void 0 : _e.value, noMargin: true, register: register("".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesCGVFieldsName.PENALTY_CLAUSE_VALUE), {
                            required: true,
                            min: 0,
                            max: isPenaltyClausePercentage ? 100 : undefined,
                        }) })))))));
};
