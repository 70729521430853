import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller } from 'react-hook-form';
import { CustomSelect } from 'shared/io';
import { useGetConstants } from 'shared/utils/selectors';
import { PostalPreferencesFieldsName } from '../PreferencesForm.constants';
import styleIdentifiers from './PostalPreferencesPartialForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var PostalPreferencesPartialForm = function () {
    var t = useTranslation().t;
    var _a = useGetConstants(), print_colors = _a.print_colors, print_modes = _a.print_modes;
    return (React.createElement("div", { className: styles('grid-row', 'postal-send-partial-form') },
        React.createElement("div", { className: styles('col-4') },
            React.createElement(Controller, { name: PostalPreferencesFieldsName.PRINT_COLOR, rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: PostalPreferencesFieldsName.PRINT_COLOR, label: t(i18nKeys.FORM.PREFERENCES.COLOR), items: print_colors, keyText: "description", keyValue: "value" })); } })),
        React.createElement("div", { className: styles('col-4') },
            React.createElement(Controller, { name: PostalPreferencesFieldsName.PRINT_MODE, rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: PostalPreferencesFieldsName.PRINT_MODE, label: t(i18nKeys.FORM.PREFERENCES.PRINTING), items: print_modes, keyText: "description", keyValue: "value" })); } }))));
};
