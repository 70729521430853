import { __rest } from "tslib";
import classNames from 'classnames/bind';
import React from 'react';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import styleIdentifiers from './MultiCurrencyData.scss';
var styles = classNames.bind(styleIdentifiers);
export default function MultiCurrencyData(_a) {
    var data = _a.data, _b = _a.withTotal, withTotal = _b === void 0 ? false : _b, prefix = _a.prefix;
    var localized_total = data.localized_total, otherCurrency = __rest(data, ["localized_total"]);
    var company = useSelector(function (state) { return state.account.company.data; });
    var multipleCurrency = Object.keys(otherCurrency).length > 1;
    return (React.createElement("div", { className: styles('MultiCurrencyData') },
        Object.keys(otherCurrency).map(function (currency) { return (React.createElement("div", { key: currency, className: styles('deviseLine') },
            React.createElement(Amount, { key: currency, value: otherCurrency[currency], suffix: currency, prefix: prefix }))); }),
        withTotal && (multipleCurrency || Object.keys(otherCurrency)[0] !== company.currency) && (React.createElement("div", { className: styles('deviseLineTotal') },
            React.createElement(Amount, { value: localized_total, prefix: prefix })))));
}
